import React, { useEffect } from 'react';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Flags from '../Flags';
import acceptedBrands from './accepted-brands';
import { PaymentMethodUi } from './style';

import { usePayment } from '../../../hooks/payment';
import { toFirstCapitalAllWords } from '../../../utils/to-first-capital';
import { PaymentMethods } from '../../../services/payment/interfaces/ICheckoutPaymentDTO';

export interface PaymentTypeSelectionProps {
  gateway?: string;
  salesOrganization?: string;
  setIsWireTransfer?: (arg: boolean) => void;
}

const paymentMethodsView =
  process.env.REACT_APP_PAYMENT_METHODS_VIEW?.split(',');

const allowedWireTransferSystems =
  process.env.REACT_APP_ALLOWED_WIRE_TRANSFER_SYSTEMS?.split(',');

const PaymentTypeSelection = ({
  gateway,
  salesOrganization,
  setIsWireTransfer,
}: PaymentTypeSelectionProps): JSX.Element => {
  const { t } = useTranslation('checkout');
  const { paymentMethod, setPaymentMethod, checkoutHook } = usePayment();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(
      (event.target as HTMLInputElement).value as PaymentMethods,
    );
  };

  const isDebitOrCredit = () => {
    return paymentMethod === 'CREDIT' || paymentMethod === 'DEBIT';
  };

  const setWireTransfer = (): void => {
    if (setIsWireTransfer) {
      if (paymentMethod === 'WIRE_TRANSFER') {
        setIsWireTransfer(true);
      } else setIsWireTransfer(false);
    }
  };

  useEffect(() => {
    if (
      checkoutHook &&
      checkoutHook.application === 'store' &&
      ['BJGX', 'SINX'].includes(checkoutHook.salesOrganization)
    )
      setPaymentMethod(PaymentMethods.WIRE_TRANSFER);
  }, [checkoutHook, setPaymentMethod]);

  return (
    <PaymentMethodUi>
      <Grid container justifyContent="space-between">
        <Grid>
          <FormControl component="fieldset">
            <FormLabel component="legend">{t('Payment Method')}</FormLabel>
            <RadioGroup
              aria-label="payment methods"
              name="payment-methods"
              className="radio-group"
              value={paymentMethod}
              onChange={handleChange}
            >
              {checkoutHook &&
              checkoutHook.application === 'store' &&
              ['BJGX', 'SINX'].includes(checkoutHook.salesOrganization) ? (
                <FormControlLabel
                  disabled={checkoutHook?.transactionType === 'WIRE_TRANSFER'}
                  key="WIRE_TRANSFER"
                  value="WIRE_TRANSFER"
                  control={<Radio color="primary" />}
                  label={t('Wire Transfer')}
                />
              ) : (
                <>
                  <FormControlLabel
                    value="CREDIT"
                    control={<Radio color="primary" />}
                    label={t('Credit')}
                    disabled={checkoutHook?.transactionType === 'WIRE_TRANSFER'}
                  />
                  {checkoutHook &&
                    !['ESL1', 'FLL1', 'EEJS', 'BJG1', 'LBG1', 'ENLX'].includes(
                      checkoutHook.salesOrganization,
                    ) && (
                      <>
                        <FormControlLabel
                          disabled={
                            checkoutHook?.transactionType === 'WIRE_TRANSFER'
                          }
                          value="DEBIT"
                          control={<Radio color="primary" />}
                          label={t('Debit')}
                        />
                        {paymentMethodsView?.map(
                          (payments: string): JSX.Element | null => {
                            if (payments === 'TICKET') {
                              if (
                                checkoutHook?.salesOrganization === 'SJK1' &&
                                checkoutHook?.paymentCurrency === 'BRL'
                              ) {
                                return (
                                  <FormControlLabel
                                    key={payments}
                                    value={payments}
                                    control={<Radio color="primary" />}
                                    label={t(toFirstCapitalAllWords(payments))}
                                    disabled={
                                      checkoutHook?.transactionType ===
                                      'WIRE_TRANSFER'
                                    }
                                  />
                                );
                              }
                              return null;
                            }

                            return null;
                          },
                        )}
                      </>
                    )}
                  {checkoutHook &&
                  allowedWireTransferSystems?.includes(
                    checkoutHook?.application,
                  ) ? (
                    <>
                      {setWireTransfer()}
                      <FormControlLabel
                        disabled={
                          checkoutHook?.transactionType === 'TICKET' ||
                          checkoutHook?.transactionType === 'WIRE_TRANSFER'
                        }
                        key="WIRE_TRANSFER"
                        value="WIRE_TRANSFER"
                        control={<Radio color="primary" />}
                        label={t('Wire Transfer')}
                      />
                    </>
                  ) : null}
                </>
              )}
            </RadioGroup>
          </FormControl>
        </Grid>

        {isDebitOrCredit() ? (
          <Grid item className="accepted-cards">
            <Grid container>
              <Grid
                item
                style={{ display: 'flex', alignItems: 'center', margin: '5px' }}
              >
                {t('Payment with ')}
              </Grid>
              <Grid item>
                <Flags
                  show
                  cardBrands={acceptedBrands(
                    gateway || '',
                    salesOrganization || '',
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </PaymentMethodUi>
  );
};

export default PaymentTypeSelection;
